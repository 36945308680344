// ======================================
//                  INDEX
// ======================================
import LoadingImage from './assets/media/loader.gif';
export const LocalStorageURL = 'http://akseer.org';

export const minusValueCheckGlobal = (value) => {
  let parsedNumber = null;

  // check if condition is parsable value

  if (isNaN(value) || value == null) {
    parsedNumber = '0.00';
  } else {
    parsedNumber = value;
    /*
     parsedNumber = (Math.round(parsedNumber * 100) / 100).toFixed(2);
  */
    if (parsedNumber < 0) {
      parsedNumber = parsedNumber * -1;

      //   parsedNumber = Math.abs(parsedNumber);
      /* if (isInt(parsedNumber)) {
        parsedNumber = parsedNumber + '.0';
      } */
      parsedNumber = parsedNumber
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      parsedNumber = '(' + parsedNumber + ')';
      // parsedNumber = (Math.round(parsedNumber * 100) / 100).toFixed(2);
    } else {
      parsedNumber = parsedNumber
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  }

  // Round numbers

  return parsedNumber;
};

export const minusValueCheckGlobalDPS = (value) => {
  let parsedNumber = null;

  // check if condition is parsable value

  if (isNaN(value) || value == null) {
    parsedNumber = '0.00';
  } else {
    parsedNumber = value;
    /*
     parsedNumber = (Math.round(parsedNumber * 100) / 100).toFixed(2);
  */
    if (parsedNumber < 0) {
      parsedNumber = parsedNumber * -1;
      parsedNumber = parsedNumber.toFixed(1);
      parsedNumber = parsedNumber
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      parsedNumber = '(' + parsedNumber + ')';
      if (isInt(parsedNumber)) {
        parsedNumber = '(' + parsedNumber + '.0)';
      }
      //   parsedNumber = Math.abs(parsedNumber);

      // console.log(parsedNumber);
      // parsedNumber = (Math.round(parsedNumber * 100) / 100).toFixed(2);
    } else {
      parsedNumber = parsedNumber
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  }

  // Round numbers

  return parsedNumber;
};
/*
    1. value check and parse  
*/

export const addDigit = (value, digit = 1) => {
  let number = parseFloat(value);
  if (number < 0) {
    // number = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    number = '(' + Math.abs(number).toFixed(digit) + ')';
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  number = number.toFixed(digit);
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const addDigitWithSign = (value, digit = 1) => {
  let number = parseFloat(value);
  if (number < 0) {
    number = '-' + Math.abs(number).toFixed(digit);
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  number = number.toFixed(digit);
  number = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return '+' + number;
};

export const valueCheckGlobalForFinancials = (value) => {
  let parsedNumber = null;

  // check if condition is parsable value

  if (isNaN(value) || value == null) {
    parsedNumber = '0';
  } else {
    parsedNumber = Number(value);
    if (parsedNumber < 0) {
      parsedNumber = Math.abs(parsedNumber);
      parsedNumber = parsedNumber
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      parsedNumber = '(' + parsedNumber + ')';
    } else {
      parsedNumber = Math.abs(parsedNumber);
      parsedNumber = parsedNumber
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      parsedNumber = parsedNumber;
    }
  }
  return parsedNumber;
};

export const valueCheckGlobalNew = (value, digit = 1) => {
  let parsedNumber = null;

  // check if condition is parsable value

  if (isNaN(value) || value == null) {
    parsedNumber = '0';
  } else {
    parsedNumber = Number(value).toFixed(digit);
    if (parsedNumber < 0) {
      parsedNumber = Math.abs(parsedNumber);
      parsedNumber = parsedNumber
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      parsedNumber = '(' + parsedNumber + ')';
    } else {
      parsedNumber = Math.abs(parsedNumber);
      parsedNumber = parsedNumber
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      parsedNumber = parsedNumber;
    }
  }
  return parsedNumber;
};
export const valueCheckGlobal = (value, addpercentAge = false, digit = 1) => {
  let parsedNumber = null;

  // check if condition is parsable value

  if (isNaN(value) || value == null) {
    parsedNumber = '0';
  } else {
    parsedNumber = Number(value);
    if (parsedNumber < 0) {
      parsedNumber = addpercentAge
        ? `${(parsedNumber / parsedNumber) * -parsedNumber}%`
        : (parsedNumber / parsedNumber) * -parsedNumber;
      parsedNumber = parsedNumber
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      if (parsedNumber >= 1000) {
        if (addpercentAge) {
          // parsedNumber = parseFloat(parsedNumber).toFixed(digit);
          parsedNumber = '(' + Math.round(parsedNumber).toLocaleString() + '%)';
          parsedNumber = parsedNumber
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        } else {
          // parsedNumber = parseFloat(parsedNumber).toFixed(digit);
          parsedNumber = '(' + Math.round(parsedNumber).toLocaleString() + ')';
          parsedNumber = parsedNumber
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
      } else {
        if (addpercentAge) {
          parsedNumber = '(' + parseFloat(parsedNumber).toFixed(digit) + '%)';
          parsedNumber = parsedNumber
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        } else {
          parsedNumber = parseFloat(parsedNumber).toFixed(digit);
          parsedNumber = parsedNumber
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          parsedNumber = '(' + parsedNumber + ')';
        }
      }
    } else if (parsedNumber >= 0 && parsedNumber < 1000) {
      parsedNumber = addpercentAge
        ? `${parseFloat(parsedNumber).toFixed(digit)}%`
        : parseFloat(parsedNumber).toFixed(digit);
      parsedNumber = parsedNumber
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      //console.log(parsedNumber);
      // console.log(addpercentAge, parseFloat(parsedNumber).toFixed(0));
    } else {
      parsedNumber = parseFloat(parsedNumber).toFixed(digit);
      // console.log(parsedNumber);
      parsedNumber = addpercentAge
        ? `${parsedNumber.toLocaleString()}%`
        : parsedNumber.toLocaleString();
      parsedNumber = parsedNumber
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  }

  // Round numbers

  return parsedNumber;
};

// Header Object

export const formatMoney = (n) => {
  return (Math.round(n * 100) / 100).toLocaleString();
};

// Market Cap Calculation

export const FindMarketCap = (stock_price, total_outstanding_share) => {
  // console.log(stock_price * total_outstanding_share);
  return stock_price * total_outstanding_share;
};

// Find Enterprise Value

export const FindEnterpriseValue = (market_cap, total_debt, cash) => {
  let ans = market_cap + total_debt - cash;
  return ans;
};

// SPECIAL CHARACTERS REMOVER

export const removeSpecialCharacters = (string) => {
  var newString = string.replace(/[^A-Z0-9]/gi, '_');
  return newString;
};

export const removeObjectWithId = (arr, key, value) => {
  const objWithIdIndex = arr.findIndex((obj) => obj.key === value);

  if (objWithIdIndex > -1) {
    arr.splice(objWithIdIndex, 1);
  }

  return arr;
};

export const getLoader = () => {
  return (
    <div
      style={{ width: '100%', height: '100%', color: '#426fd7' }}
      className="main-loader d-flex flex-column justify-content-center align-items-center"
    >
      <img src={LoadingImage} style={{ height: '500px' }} />
    </div>
  );
};

export const formatDate = (date) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  let month_name = months[d.getMonth()];
  // 08 January, 2024
  return day + ' ' + month_name + ',' + ' ' + year;

  return [year, month, day].join('-');
};

export const isInt = (n) => {
  return Number(n) === n && n % 1 === 0;
};

export const isFloat = (n) => {
  return Number(n) === n && n % 1 !== 0;
};
